import {set} from "@vueuse/core";
import {fullHash} from "~/composable/fullHash";
import {getDevices} from "~/composable/getDevices";
import {EXCLUDE, INCLUDE} from "~/const/ANALYTICS";
import {getBrowsers} from "~/composable/getBrowsers";
import {getCountries} from "~/composable/getCountries";
import md5 from "crypto-js/md5";

export const useTrafficNetworksStore = defineStore('trafficNetworksStore', () => {
	const trafficNetworks = ref([]);

	async function fetchTrafficNetworks() {
		const authStore = useAuthStore();
		const id = authStore.userInfo.id;
		const networks = await $fetch('/api/traffic-networks/get', {
			query: {
				id,
			}
		});
		set(trafficNetworks, networks);
	}

	async function saveKey(user_id, traffic_network_id, api_key) {
		await $fetch('/api/traffic-networks/save-key', {
			method: 'POST',
			query: {
				user_id,
				traffic_network_id,
				api_key,
			}
		});
	}

	const getTrafficNetworksById = computed(() => (id) =>{
		return Object.values(toValue(trafficNetworks)).find((network) => network.id === id)
	});
	return {
		trafficNetworks,
		getTrafficNetworksById,
		fetchTrafficNetworks,
		saveKey
	}
})
